<template>
  <b-container fluid="md">
    <h1 class="text-capitalize-first-word">
      {{ title }}
    </h1>
    <BaseDetailModals
      v-model="modals"
      @delete-entity="deleteEntity"
      @delete-draft="deleteDraft"
      @publish="publish"
      @cancel-leave="leaveGuard.modalActive = false"
      @perform-leave="$router.push(leaveGuard.to)"
    />

    <slot name="above-form"></slot>

    <b-form ref="form" :validated="wasValidated">
      <slot :draft="draft" :changed="changed">
        <b-form-group
          v-for="[name, value] in valuesSelection(draft)"
          :id="'label-' + name"
          :key="name"
          :label="
            $t(localeSection + '.' + name) +
              (isChangedInDraft(name, value)
                ? ' ' +
                  $t('components.baseDetail.edited', {
                    value: isSelectable(name)
                      ? selectable[name].find(item => item.value == value.id)
                          .text
                      : value
                  })
                : '') +
              (getInputAttribute('required', name, false)
                ? ' ' + $t('general.required')
                : '')
          "
          :label-for="'form-element-' + name"
          :state="!validationErrors[name]"
          :invalid-feedback="validationErrors[name]"
        >
          <b-form-input
            v-if="isInput(name)"
            :id="'form-element-' + name"
            v-model="draft[name]"
            :type="getInputAttribute('type', name, 'text')"
            :length="
              getInputAttribute('type', name, 'text') == 'text' &&
              getFieldLength(name)
                ? getFieldLength(name)
                : undefined
            "
            :maxlength="
              getInputAttribute('type', name, 'text') == 'text' &&
              getFieldLength(name)
                ? getFieldLength(name)
                : undefined
            "
            :required="getInputAttribute('required', name, null)"
            :pattern="getInputAttribute('pattern', name, null)"
            @change="changed"
            @input="changed"
          />
          <b-row
            v-if="
              name in alternativeToggles &&
                selectable[name + '__alternative'].length > 0
            "
          >
            <b-col>
              {{ $t(localeSection + ".alternative." + name + ".header") }}
              <b-form-checkbox
                v-model="alternativeToggles[name]"
                switch
                class="d-inline"
              >
                {{
                  alternativeToggles[name] === true
                    ? $t(localeSection + ".alternative." + name + ".yes")
                    : $t(localeSection + ".alternative." + name + ".no")
                }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <multiselect
            v-if="
              isSelectable(name) &&
                !isRelation(name) &&
                name in alternativeToggles
            "
            :id="'form-element-alternative-' + name"
            v-model="draft[name].id"
            :options="selectable[getSelectableName(name)].map(i => i.value)"
            :custom-label="
              key =>
                selectable[getSelectableName(name)].find(x => x.value == key)
                  .text
            "
            :show-labels="false"
            @select="changed"
          />
          <multiselect
            v-if="
              isSelectable(name) &&
                !isRelation(name) &&
                !(name in alternativeToggles)
            "
            :id="'form-element-' + name"
            ref="multiselects"
            v-model="draft[name].id"
            :data-name="name"
            :options="selectable[name].map(i => i.value)"
            :custom-label="
              key => selectable[name].find(x => x.value == key).text
            "
            :show-labels="false"
            @input="changed"
          />
          <b-form-radio-group
            v-if="isRadio(name)"
            :id="'form-element-' + name"
            v-model="draft[name]"
            :options="radioOptions[name]"
            :required="getInputAttribute('required', name, null)"
            buttons
            button-variant="outline-primary"
            @change="changed"
          />
          <b-form-checkbox
            v-if="isToggle(name)"
            :id="'form-element-' + name"
            v-model="draft[name]"
            :required="getInputAttribute('required', name, null)"
            switch
            @change="changed"
          >
            {{ $t(localeSection + "." + getToggle(name, draft[name])) }}
          </b-form-checkbox>
          <b-form-group v-if="isRelation(name)">
            <b-card v-for="(item, index) in value" :key="name + index" no-body>
              <b-row
                v-if="
                  name in alternativeToggles &&
                    selectable[name + 'alternative'].length > 0
                "
              >
                <b-col>
                  {{ $t(localeSection + ".alternative." + name + ".header") }}
                  <b-form-checkbox
                    v-model="alternativeToggles[name]"
                    switch
                    class="d-inline"
                  >
                    {{
                      alternativeToggles[name] === true
                        ? $t(localeSection + ".alternative." + name + ".yes")
                        : $t(localeSection + ".alternative." + name + ".no")
                    }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <multiselect
                v-if="isSelectable(name) && name in alternativeToggles"
                :id="'form-element-alternative-' + name"
                v-model="draft[name].id"
                :options="selectable[getSelectableName(name)].map(i => i.value)"
                :custom-label="
                  key =>
                    selectable[getSelectableName(name)].find(
                      x => x.value == key
                    ).text
                "
                :show-labels="false"
                @select="changed"
              />
              <multiselect
                v-if="isSelectable(name) && !(name in alternativeToggles)"
                :id="'form-element-' + name + '-' + index"
                v-model="draft[name][index].id"
                :options="selectable[name].map(i => i.value)"
                :custom-label="
                  key => selectable[name].find(x => x.value == key).text
                "
                :show-labels="false"
                @select="changed"
              />
              <b-card-body v-if="!isSelectable(name)">
                <b-form-group
                  v-for="[iname] in valuesSelection(item)"
                  :id="'label-' + iname + '-' + index"
                  :key="iname + index"
                  :label="$t(localeSection + '.' + iname)"
                  :label-for="'form-element-' + iname + '-' + index"
                >
                  <multiselect
                    v-if="isSelectable(iname)"
                    :id="'form-element-' + iname + '-' + index"
                    v-model="draft[name][index][iname].id"
                    :options="selectable[iname].map(i => i.value)"
                    :custom-label="
                      key => selectable[iname].find(x => x.value == key).text
                    "
                    :show-labels="false"
                    @select="changed"
                  />
                  <b-form-radio-group
                    v-if="isRadio(iname)"
                    :id="'form-element-' + iname + '-' + index"
                    v-model="draft[name][index][iname]"
                    :options="radioOptions[iname]"
                    buttons
                    button-variant="outline-primary"
                    @change="changed"
                  />
                  <b-form-checkbox
                    v-if="isToggle(iname)"
                    :id="'form-element-' + iname + '-' + index"
                    v-model="draft[name][index][iname]"
                    switch
                    @change="changed"
                  >
                    {{
                      $t(
                        localeSection +
                          "." +
                          getToggle(iname, draft[name][index][iname])
                      )
                    }}
                  </b-form-checkbox>
                </b-form-group>
              </b-card-body>
              <b-card-footer>
                <b-button
                  v-t="localeSection + '.delete' + name"
                  variant="outline-primary"
                  @click="deleteRelation(name, index)"
                ></b-button>
              </b-card-footer>
            </b-card>
            <b-button
              v-t="localeSection + '.new' + name"
              @click="addRelation(name)"
            ></b-button>
          </b-form-group>
        </b-form-group>
      </slot>
      <hr />
      <b-form-group
        :label="$t('components.baseDetail.commentLabel')"
        label-for="draft-comment"
      >
        <b-form-textarea
          id="draft-comment"
          :key="'comment-' + commentKey"
          v-model="draftDetails.comment"
          :disabled="!edited && !isDraft"
          :placeholder="$t('components.baseDetail.commentPlaceHolder')"
          class="comment-area"
          rows="3"
          max-rows="15"
          @input="setEdited(true)"
        />
      </b-form-group>
      <b-form-group
        :label="$t('components.baseDetail.publicCommentLabel')"
        label-for="draft-public-comment"
      >
        <ckeditor
          v-if="edited || isDraft"
          v-model="draftDetails.publicComment"
          :editor="publicCommentEditor"
          :config="publicCommentEditorConfig"
          class="public-comment-area"
          @input="setEdited(true)"
        />
        <!-- Use class form-control and the readonly attribute to emulate a disabled textarea
             and get the same grayed-out look as the comment field. -->
        <div v-else class="form-control comment-disabled" readonly>
          {{
            draftDetails.publicComment || publicCommentEditorConfig.placeholder
          }}
        </div>
      </b-form-group>

      <b-form-row v-if="!edited && !isDraft" class="form-group">
        <b-col>
          <p v-t="'components.baseDetail.disabledCommentsExplanation'" />
        </b-col>
      </b-form-row>

      <b-form-row class="form-group form-group-buttons">
        <b-col lg="auto" md="12">
          <b-button
            key="button-save"
            v-t="'general.save'"
            :disabled="!edited"
            block
            class="mb-2"
            variant="primary"
            @click="save"
          />
        </b-col>
        <b-col lg="auto" md="12">
          <b-button
            key="button-reset"
            v-t="'general.reset'"
            :disabled="!edited"
            block
            class="mb-2"
            variant="outline-primary"
            @click="reset"
          />
        </b-col>
        <b-col v-if="!isDraft && !isNew" lg="auto" md="12">
          <b-button
            key="button-delete"
            v-t="localeSection + '.delete'"
            v-b-modal
            block
            class="mb-2"
            variant="outline-primary"
            @click="showModal('deleteEntity')"
          />
        </b-col>
        <b-col v-if="isDraft" lg="auto" md="12">
          <b-button
            key="button-delete-draft"
            v-t="'general.deleteDraft'"
            v-b-modal
            block
            class="mb-2"
            variant="outline-primary"
            @click="showModal('deleteDraft')"
          />
        </b-col>

        <b-col v-if="isDraft" lg="auto" md="12">
          <b-button
            key="button-publish"
            v-t="'general.publish'"
            v-b-modal
            block
            class="mb-2"
            variant="outline-primary"
            @click="showModal('publishDraft')"
          />
        </b-col>
      </b-form-row>
    </b-form>
  </b-container>
</template>

<script>
import gql from "graphql-tag";
import Utils from "@/utils";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { isObject } from "@vue/shared";

export default {
  name: "BaseDetailSimple",
  components: {
    BaseDetailModals: () => import("./BaseDetailModals")
  },
  props: {
    queryValues: {
      type: String,
      default: ""
    },
    querySelects: {
      type: String,
      default: ""
    },
    entityType: {
      type: String,
      default: ""
    },
    localeSection: {
      type: String,
      default: "default"
    },
    inputAttributes: {
      type: Object,
      default: () => ({})
    },
    radio: {
      type: Object,
      default: () => ({})
    },
    toggle: {
      type: Object,
      default: () => ({})
    },
    relations: {
      type: Object,
      default: () => ({})
    },
    leaveGuard: {
      type: Object,
      default: null
    },
    fieldSection: {
      type: String,
      required: true
    },
    alternativeSelects: {
      type: Array,
      default: () => []
    },
    /*
      A custom function to retrieve the name if the element is complex,
      like for example the signum objects which have signum1 and signum2
    */
    nameMap: {
      type: Function,
      default: () => null
    }
  },
  data() {
    return {
      values: {},
      draft: {},
      draftDetails: {},
      selectable: {},
      alternativeToggles: {},
      edited: false,
      nilUUID: "00000000-0000-0000-0000-000000000000",
      // keys used to make sure b-form-textareas are updated, see https://github.com/bootstrap-vue/bootstrap-vue/issues/3103
      commentKey: 0,
      publicCommentKey: 0,
      publicCommentEditor: ClassicEditor,
      publicCommentEditorConfig: {
        placeholder: this.$t("components.baseDetail.publicCommentPlaceHolder"),
        toolbar: [
          "heading",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList"
        ]
      },
      modals: {
        visible: {
          publishDraft: false,
          deleteEntity: false,
          deleteDraft: false,
          unsavedChanges: false
        },
        data: {
          deleteEntityName: this.entityName
        }
      },
      wasValidated: false,
      validationErrors: {}
    };
  },
  computed: {
    entityName() {
      return this.values && (this.values._name || this.nameMap(this.values));
    },
    comment() {
      return this.draftDetails && this.draftDetails.comment
        ? this.draftDetails.comment
        : "";
    },
    publicComment() {
      return this.draftDetails && this.draftDetails.publicComment
        ? this.draftDetails.publicComment
        : "";
    },
    isDraft() {
      return this.draftDetails != null && this.draftDetails.draftId != null;
    },
    isNew() {
      return this.$route.params.id == this.nilUUID;
    },
    userName() {
      return this.$root.user !== undefined ? this.$root.user.username : null;
    },
    radioOptions() {
      let options = {};
      for (let key in this.radio) {
        let keyopts = [];
        for (let optkey in this.radio[key]) {
          keyopts.push({
            text: this.$t(this.localeSection + "." + optkey),
            value: this.radio[key][optkey]
          });
        }
        options[key] = keyopts;
      }
      return options;
    },
    title() {
      return (
        this.$t(this.localeSection + ".detailHeader") +
        (this.entityName ? ": " + this.entityName : "")
      );
    },
    capitalizedEntityType() {
      return this.entityType.charAt(0).toUpperCase() + this.entityType.slice(1);
    },
    leaveGuardModalActive() {
      return this.leaveGuard.modalActive;
    }
  },
  metaInfo() {
    return {
      title: this.title
    };
  },
  watch: {
    edited(newEdited) {
      if (this.leaveGuard) {
        this.leaveGuard.hasChanged = newEdited;
      }
    },
    leaveGuardModalActive(modalActive) {
      if (modalActive) {
        this.showModal("unsavedChanges");
        // = this.leaveGuard.modalActive;
      }
    }
  },
  mounted: function() {
    this.alternativeSelects.forEach(select => {
      this.$set(this.alternativeToggles, select, false);
    });
  },
  methods: {
    valuesSelection(object) {
      // Remove fields we shouldn't show (starting with _)
      return Object.entries(object).filter(
        // eslint-disable-next-line no-unused-vars
        ([key, value]) => key[0] !== "_"
      );
    },
    isChangedInDraft(name, value) {
      return (
        typeof this.draft[name] !== "undefined" &&
        (this.isSelectable(name)
          ? this.draft[name].id !== value.id
          : this.draft[name] !== value)
      );
    },
    save() {
      if (this.validateForm()) {
        this.$apollo
          .mutate({
            mutation: gql`
            mutation update${this.capitalizedEntityType}($input: ${this.capitalizedEntityType}Input) {
              update${this.capitalizedEntityType}(input: $input) {
                id
              }
            }
          `,
            // Parameters
            variables: {
              input: {
                id: this.$route.params.id,
                ...this.draft,
                comment: this.comment,
                publicComment: this.publicComment
              }
            }
          })
          .then(data => {
            this.setEdited(false);
            this.makeToast({
              message: this.$t("general.saved"),
              variant: "success"
            });
            if (this.isNew) {
              this.$router.push({
                name: this.$route.name,
                params: {
                  id: data.data[Object.keys(data.data)[0]].id
                }
              });
              this.$apollo.queries.values.setVariables({
                id: data.data[Object.keys(data.data)[0]].id
              });
            } else {
              this.$apollo.queries.values.refetch();
            }
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.error(
              "Could not save. Reason from server: " + error.message
            );
            this.makeToast({
              message: this.$t("general.error"),
              variant: "danger"
            });
          });
      } else {
        this.makeToast({
          message: this.$t("general.notSaved"),
          variant: "danger"
        });
      }
    },
    publish() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation publish${this.capitalizedEntityType}($id: ID) {
              publish${this.capitalizedEntityType}(id: $id)
            }
          `,
          // Parameters
          variables: { id: this.draftDetails.originalId }
        })
        .then(() => {
          this.makeToast({
            message: this.$t("general.published"),
            variant: "success"
          });
          this.$apollo.queries.values.refetch();
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);
          this.makeToast({
            message: this.$t("general.error"),
            variant: "danger"
          });
        });
    },
    reset() {
      this.values = {};
      this.draft = {};
      this.setEdited(false);

      this.$apollo.queries.values.refetch();
    },
    deleteEntity() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation delete${this.capitalizedEntityType}($id: ID) {
              delete${this.capitalizedEntityType}(id: $id)
            }
          `,
          // Parameters
          variables: { id: this.$route.params.id }
        })
        .then(() => {
          this.makeToast({
            message: this.$t("general.deleted"),
            variant: "success"
          });
          this.$router.push({
            name: this.$route.name,
            params: {
              id: this.nilUUID
            }
          });
          this.$apollo.queries.values.setVariables({ id: this.nilUUID });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);
          this.makeToast({
            message: this.$t("general.error"),
            variant: "danger"
          });
        });
    },
    deleteDraft() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation delete${this.capitalizedEntityType}Draft($id: ID) {
              delete${this.capitalizedEntityType}Draft(id: $id)
            }
          `,
          // Parameters
          variables: { id: this.draftDetails.draftId }
        })
        .then(() => {
          this.makeToast({
            message: this.$t("general.deleted"),
            variant: "success"
          });
          if (this.draftDetails.originalId == this.draftDetails.draftId) {
            this.$router.push({
              name: this.$route.name,
              params: {
                id: this.nilUUID
              }
            });
            this.$apollo.queries.values.setVariables({ id: this.nilUUID });
          } else {
            this.$apollo.queries.values.refetch();
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);
          this.makeToast({
            message: this.$t("general.error"),
            variant: "danger"
          });
        });
    },
    addRelation(key) {
      this.draft[key].push(JSON.parse(JSON.stringify(this.relations[key])));
      this.changed();
    },
    deleteRelation(key, index) {
      this.draft[key].splice(index, 1);
      this.changed();
    },
    changed() {
      this.wasValidated = false;

      // if not yet draft and first edit, add time stamp to comment
      if (!this.isDraft && !this.edited) {
        this.draftDetails.comment =
          new Date().toLocaleString(this.$t("general.locale.swedish")) +
          (this.userName ? " (" + this.userName + ")" : "") +
          "\n" +
          this.$t("components.baseDetail.newCommentHere") +
          "\n\n" +
          this.comment;
      }
      // make sure b-form-textarea is updated, see https://github.com/bootstrap-vue/bootstrap-vue/issues/3103
      this.commentKey++;

      this.setEdited(true);
    },
    setEdited(newEdited) {
      this.edited = newEdited;
      this.leaveGuard.hasChanged = true;
    },
    isSelectable(key) {
      return key in this.selectable;
    },
    isRelation(key) {
      return key in this.relations;
    },
    isRadio(key) {
      return key in this.radio;
    },
    isToggle(key) {
      return key in this.toggle;
    },
    getToggle(key, value) {
      return Object.keys(this.toggle[key])[
        Object.values(this.toggle[key]).indexOf(value)
      ];
    },
    isInput(key) {
      return (
        !this.isSelectable(key) &&
        !this.isRelation(key) &&
        !this.isRadio(key) &&
        !this.isToggle(key)
      );
    },
    reduceToString(object) {
      return Object.entries(object)
        .filter(item => item[0] != "id" && item[0][0] !== "_")
        .reduce((text, value) => {
          if (value[1] === null) {
            return text;
          }
          if (text === "") {
            return typeof value[1] !== "object"
              ? value[1]
              : this.reduceToString(value[1]);
          } else {
            return (
              text +
              "/" +
              (typeof value[1] !== "object"
                ? value[1]
                : this.reduceToString(value[1]))
            );
          }
        }, "");
    },
    scrubProperties(object) {
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
          if (object[key] != null && typeof object[key] == "object") {
            this.scrubProperties(object[key]);
          } else {
            if (key[0] == "_") {
              delete object[key];
            }
          }
        }
      }
    },
    makeToast({ title, message, append, variant, toaster }) {
      this.$bvToast.toast(message, {
        title: title || this.$t("general.toastTitle"),
        solid: true,
        autoHideDelay: 5000,
        appendToast: append,
        variant,
        toaster: toaster || "b-toaster-top-center"
      });
    },
    showModal(type) {
      this.modals.visible[type] = true;
    },
    getFieldLength(field) {
      return Utils.getFieldLength(this.fieldSection, field);
    },
    getSelectableName(key) {
      if (
        key in this.alternativeToggles &&
        this.selectable[key + "__alternative"].length > 0 &&
        !this.alternativeToggles[key]
      ) {
        return key + "__alternative";
      } else {
        return key;
      }
    },
    /**
     * Returns true if the multiselect is valid, false if it is not and null if
     * unspecified by the prop inputAttributes.
     */
    validateMultiselect(name, value) {
      const attributes = this.inputAttributes[name];

      if (!attributes) {
        return null;
      }

      /* required */
      if (attributes.required && !value) {
        this.validationErrors[name] = this.$t("general.requiredError");
        return false;
      } else {
        this.validationErrors[name] = undefined;
      }

      /* pattern */
      // Multiselect values should always be correct, so this code will probably not
      // be used that much.
      if (attributes.pattern && (!value || !value.match(attributes.pattern))) {
        this.validationErrors[name] = this.$t(
          "general.invalidFormat",
          attributes.pattern
        );
        return false;
      } else {
        this.validationErrors[name] = undefined;
      }

      return true;
    },
    updateValidationStatus(element, isValid) {
      element.classList.remove(isValid ? "is-invalid" : "is-valid");
      element.classList.add(isValid ? "is-valid" : "is-invalid");
      element.classList.add("form-control");
    },
    removeValidationStatus(element) {
      element.classList.remove("form-control");
      element.classList.remove("is-invalid");
      element.classList.remove("is-valid");
    },
    validateForm() {
      let formIsValid = true;

      // Multiselects are not real inputs and need to be validated separately.
      if (this.$refs.multiselects) {
        this.$refs.multiselects.forEach(multiselect => {
          const name = multiselect.$attrs["data-name"];
          const value = multiselect.getValue();
          const formControl = multiselect.$el.querySelector(
            ".multiselect__tags"
          );
          const fieldIsValid = this.validateMultiselect(name, value);

          if (fieldIsValid === null) {
            this.removeValidationStatus(formControl);
          } else {
            formIsValid = formIsValid && fieldIsValid;

            this.updateValidationStatus(formControl, fieldIsValid);
          }
        });
      }

      // Validate standard inputs.
      formIsValid = formIsValid && this.$refs.form.checkValidity();

      this.wasValidated = true;
      return formIsValid;
    },
    isMultiselectInvalid(name) {
      return (
        this.inputAttributes[name] &&
        this.inputAttributes[name].required &&
        !(
          this.draft[name] &&
          this.draft[name].id &&
          this.draft[name].id.length > 0
        )
      );
    },
    getInputAttribute(key, name, defaultValue) {
      return (
        (this.inputAttributes[name] && this.inputAttributes[name][key]) ||
        defaultValue
      );
    }
  },
  apollo: {
    values() {
      return {
        query: gql`
          query ${this.entityType}($id: ID!) {
            values: ${this.entityType}(id: $id) {
              ${this.queryValues}
            }
            draft: ${this.entityType}Draft(id: $id) {
              ${this.queryValues}
            }
            draftDetails: draft(originalId: $id) {
              originalId
              draftId
              comment
              publicComment
            }
            ${this.querySelects}
          }
        `,
        variables: { id: this.$route.params.id },
        result(result) {
          if (result.stale) {
            // result is called again with stale = true
            return;
          }
          var _this = this;

          // Build texts for selects
          Object.entries(result.data).forEach(([key, value]) => {
            if (!["values", "draft", "draftDetails"].includes(key)) {
              _this.selectable[key] = value
                .map(entry => {
                  let text = this.reduceToString(entry);
                  return {
                    value: entry["id"],
                    text: text ? text : entry["id"]
                  };
                })
                .sort((entry0, entry1) =>
                  // FIXME Replace hard-coded value "sv" with prop, constant or something else.
                  entry0.text.localeCompare(entry1.text, "sv")
                );
            }
          });

          this.values = result.data.values || {};
          // Relations might be null for new entities, make them empty instead
          // Selectable relations need a placeholder also
          for (var key in this.values) {
            if (this.isRelation(key) && this.values[key] == null) {
              this.values[key] = [];
            }
            if (this.isSelectable(key) && this.values[key] == null) {
              this.values[key] = { id: null };
            }
          }
          // Make draft a copy of the current entity if no draft exists
          this.draft =
            result.data.draft || JSON.parse(JSON.stringify(this.values));
          // Get rid of __typename field and all fields starting with _
          // (See warning at https://akryum.github.io/vue-apollo/guide/apollo/mutations.html)
          this.scrubProperties(this.draft);
          for (var key in this.draft) {
            // sanity check for drafts
            if (this.isRelation(key) && this.draft[key] == null) {
              this.draft[key] = [];
            }
            if (this.isSelectable(key) && this.draft[key] == null) {
              this.draft[key] = { id: null };
            }
            // Toggle to all values för select if there is a value
            if (isObject(this.draft[key])) {
              if (
                key in this.alternativeSelects &&
                this.draft[key].id !== null
              ) {
                this.$set(this.alternativeToggles, key, true);
              }
            }
          }
          this.draftDetails = result.data.draftDetails || {
            comment: "",
            publicComment: ""
          };

          // Make sure b-form-textareas are updated, see https://github.com/bootstrap-vue/bootstrap-vue/issues/3103
          this.commentKey++;
          this.publicCommentKey++;
        }
      };
    }
  }
};
</script>
