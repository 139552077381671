import fieldLengths from "@/fieldlengths";

function getFieldLength(tableName, fieldName) {
  let tableKey = tableName.toLowerCase();
  let fieldKey = fieldName.toLowerCase();

  let fields = fieldLengths[tableKey];
  if (fields) {
    let fieldLength = fields[fieldKey];
    if (fieldLength) {
      return fieldLength;
    }
  }

  return undefined;
}

export default {
  getFieldLength
};
