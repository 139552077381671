// This file was generated by ./fieldlengths.sh from docker/raa-db/sql/rundata.sql at Mon Jun  3 15:58:52 DST 2019.
export default {
  signa: {
    signum1: 32,
    signum2: 32
  },
  signa1: {
    signum1: 32,
    source: 255,
    shortsource: 64
  },
  signum_inscription: {},
  artefacts: {
    artefact: 32,
    lang: 8
  },
  carver_inscription: {
    lang: 8
  },
  carver_source: {},
  carvers: {
    name: 32
  },
  coordinates: {},
  counties: {
    county: 32,
    letter: 2,
    number: 2
  },
  countries: {
    country: 5,
    sv: 32,
    en: 32
  },
  cross_crossform: {},
  cross_source: {},
  crossdescs: {
    crossdesc: 128,
    lang: 8
  },
  crosses: {},
  crossforms: {
    aspect: 1
  },
  dating: {
    dating: 128,
    lang: 8
  },
  dating_source: {},
  division_names: {
    province: 32,
    hundred: 32,
    parish: 32,
    county: 32,
    municipality: 32
  },
  findnumbers: {
    findnumber: 16
  },
  fragments: {},
  group_source: {},
  groups: {
    notes: 255,
    lang: 8
  },
  hundreds: {
    hundred: 128
  },
  imagelinks: {
    imagelink: 255
  },
  inscription_group: {},
  inscription_runetype: {},
  inscriptions: {},
  interpretation_source: {},
  interpretations: {
    language: 3
  },
  locations: {
    location: 255
  },
  material_materialsubtype: {},
  materials: {
    material: 128,
    lang: 8
  },
  materialsubtypes: {
    subtype: 32,
    lang: 8
  },
  materialtypes: {
    materialtype: 8,
    lang: 8
  },
  municipalities: {
    municipality: 32,
    number: 4
  },
  her_DK: {
    parishcode: 16,
    fofmparish: 64
  },
  her_DK_notes: {
    notes: 255,
    lang: 8
  },
  "her_GB-SCT": {
    uri: 255
  },
  her_NO: {},
  her_NO_notes: {
    notes: 255,
    lang: 8
  },
  her_SE: {
    raänr: 16
  },
  her_SE_notes: {
    notes: 255,
    lang: 8
  },
  her_SE_parishes: {
    parishcode: 5,
    raäparish: 64
  },
  notes: {
    lang: 8
  },
  object_bracteatetype: {},
  bracteatetypes: {
    bracteatetype: 8
  },
  object_artefact: {},
  object_group: {},
  object_material: {},
  object_her_DK: {},
  "object_her_GB-SCT": {},
  object_her_NO: {},
  object_her_SEe: {},
  object_source: {},
  object_style: {},
  object_uri: {},
  objects: {
    artefact: 255,
    material: 255
  },
  originallocations: {
    originallocation: 128
  },
  parishes: {
    toraid: 255,
    parish: 64
  },
  periods: {
    period: 8,
    sv: 32,
    en: 32
  },
  place_parish: {},
  places: {
    toraid: 255,
    place: 128
  },
  provinces: {
    province: 32
  },
  reading_source: {},
  readings: {},
  runetypedescs: {
    runetypedesc: 255,
    lang: 8
  },
  runetypes: {
    runetype: 32,
    category: 32
  },
  source_uris: {
    uri: 255
  },
  sources: {
    year: 16
  },
  style_source: {},
  styles: {
    style: 3
  },
  termini: {},
  translation_source: {},
  translations: {
    language: 8
  },
  uris: {
    uri: 255
  }
};
